import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/WebProjects',
    name: 'WebProjects',
    component: () => import(/* webpackChunkName: "webProjects" */ '../views/WebProjects.vue')
  },
  {
    path: '/WeatherChecker',
    name: 'WeatherChecker',
    component: () => import(/* webpackChunkName: "weatherChecker" */ '../views/WeatherPage.vue')
  },

  {
    path: '/FishBowl',
    name: 'FishBowl',
    component: () => import(/* webpackChunkName: "fishBowl" */ '../views/FishBowl.vue')
  },
  {
    path: '/caveman',
    name: 'caveman',
    component: () => import(/* webpackChunkName: "caveman" */ '../views/caveman.vue')
  },
  {
    path: '/cavemanVersions',
    name: 'cavemanVersions',
    component: () => import(/* webpackChunkName: "cavemanVersions" */ '../views/CavemanVersions.vue')
  },
  {
    path: '/jsButtons',
    name: 'JsButtons',
    component: () => import(/* webpackChunkName: "butons" */ '../views/JsButtons.vue')
  },
  {
    path: '/raycaster',
    name: 'raycaster',
    component: () => import(/* webpackChunkName: "raycaster" */ '../views/Raycaster.vue')
  },
  {
    path: '/touchPaint',
    name: 'touchPaint',
    component: () => import(/* webpackChunkName: "touchPaint" */ '../views/touchPaint.vue')
  },
  {
    path: '/touchTest',
    name: 'touchTest',
    component: () => import(/* webpackChunkName: "touchTest" */ '../views/touchTestPage.vue')
  },
  {
    path: '/carousel',
    name: 'carousel',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/CarouselPage.vue')
  },
  {
    path: '/textBoxes',
    name: 'textBoxes',
    component: () => import(/* webpackChunkName: "textBoxes" */ '../views/TextCanvas.vue')
  },
  {
    path: '/drumKit',
    name: 'drumKit',
    component: () => import(/* webpackChunkName: "drumKit" */ '../views/DrumKit.vue')
  },
  {
    path: '/bballSheet',
    name: 'bballSheet',
    component: () => import(/* webpackChunkName: "bballSheet" */ '../views/ScoreSheet.vue')
  },
  {
    path: '/RaycasterWalls',
    name: 'raycasterWalls',
    component: () => import(/* webpackChunkName: "bballSheet" */ '../views/RaycasterWallsPage.vue')
  },
  {
    path: '/ObjectsRender',
    name: 'objectRender',
    component: () => import(/* webpackChunkName: "objectRender" */ '../views/3dRender.vue')
  },
  {
    path: '/GameController',
    name: 'gameController',
    component: () => import(/* webpackChunkName: "gameController" */ '../views/GameController.vue')
  },
  {
    path: '/*',
    name: '404',
    component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/404page.vue')
  },
  
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
