<template>
  <div class="main-section-container">
    <div id="top-container">
      <div id="about-outer-container">
        <div id="about-container">
          <h1 class="site-heading">ABOUT THIS SITE</h1>

          <div id="about-text-box">
            <p>Hi I'm Ben and welcome! This site was created to showcase projects and develop skills in languages such as JavaScript, HTML and CSS(Including the website itself, built with the Vue framework). Where possible projects will be usable with desktop and touchscreen/mobile devices.</p>
            <p>Take a look around. The projects cover a wide range of purposes from Vue components and games, to projects using calls to a free API. Each page will include some details as to how it was a made and the tech used to make it. </p>
          </div>
        </div>
      </div>
      <div id="gallery-container">
        <CarouselVue
          :imageList="images"
        />
      </div>
    </div>
    <div class="featured-projects">
      <h1 class="site-heading">FEATURED PROJECTS</h1>
      <FeaturedProject
        titleText="Basketball ScoreSheet"
        :imageSource="require('@/assets/images/projectLinks/bballSheet.png')"
        :routerLink="{name: 'bballSheet'}"
        :detailsText="featureText.slot1"
      />
      <FeaturedProject
        titleText="3D Raycaster"
        :imageSource="require('@/assets/images/projectLinks/raycasterLink.png')"
        :routerLink="{name: 'raycaster'}"
        :detailsText="featureText.slot2"
      />
    </div>
  </div>
</template>

<script>
import CarouselVue from  "@/components/CarouselVueComp"
import FeaturedProject from  "@/components/page_elements/FeaturedProject"
export default {
  name: 'Home',
  components: {
    CarouselVue,
    FeaturedProject,
  },
  data() {
    return {
        images: [
          {
            imagePath: require('@/assets/images/homeCarousel/fishbowl.png'),
            descriptionText: 'Fishbowl: An animation of sea creatures rendered on an HTML canvas',
          },
          {
            imagePath: require('@/assets/images/homeCarousel/weather.png'),
            descriptionText: 'Weather Checker: Check the weather in a city or your local area',
          },
          {
            imagePath: require('@/assets/images/homeCarousel/caveman2.png'),
            descriptionText: 'Caveman: A platformer made using an editor tool',
          },
          {
            imagePath: require('@/assets/images/homeCarousel/raycaster.png'),
            descriptionText: '3D Raycaster: Able to move around a 3D enviroment using raycasting',
          },
          {
            imagePath: require('@/assets/images/homeCarousel/drumKit.png'),
            descriptionText: 'Drumkit: Use a mouse or touch to play sounds on a drum kit',
          },
      ],
      featureText: {
        slot1: 'Having been involved with basketball, I wanted the challenge of creating a way to digitally score games that was intuitive and easy to use. Allows the digital logging of game events to then produce a scoresheet in the same format as a standard written version. Also to use the data logged for the game to create a boxscore with player and team stats, which is also printable.',
        slot2: 'Navigate through a 3D area rendered using raycasting collisions generated from a 2D tile based grid.  Coded in JavaScript using 2D rendering context on an HTML canvas the rather than WebGL. Includes customisation settings to change the screen resolution, field of view(FOV) and texture the walls.',
        slot3: '',
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  $tablet-width: 950px;

  .home {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 1200px;

    @media only screen and (max-width: $tablet-width) {
     max-width: 700px;
    }
  }

  #about-outer-container {
    width: 50%;
    @media only screen and (max-width: $tablet-width) {
     width: 100%;
    }
  }

  #about-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    @media only screen and (max-width: $tablet-width) {
      padding: 0;
    }
  }

  #top-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1200px;

    @media only screen and (max-width: $tablet-width) {
     width: 100%;
     flex-direction: column;
     align-items: center;
    }
  }

  #gallery-container {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    padding-top: 25px;

    @media only screen and (max-width: $tablet-width) {
     width: 100%;
    }
  }

  #about-text-box {
    padding: 0px 10px;
    min-height: 250px;
    height: 100%;
    background: #fff;

    border: 3px solid #fff;
    border-radius: 0px 0px 25px 25px;
  }

  .featured-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

</style>
