<template>
  <div id="app">
    <div id="nav">
      <div id="nav-block">

        <div id="inner-nav">
          <router-link class="fixed-link" to="/">Home</router-link>
          <router-link to="/webprojects">All Projects</router-link>
          <a href="https://bball-scoresheet.ben-smith-development.com">ScoreSheet ></a>
          <img id="mobile-button" :class="{open: showMobileNav}" @click="toggleNav" src="@/assets/images/dropDownButton.svg">
        </div>

        <div id="mobile-nav-container">
          <div id="mobile-nav" :class="{open: showMobileNav}">
            <router-link to="/webprojects">All Projects</router-link>
            <a href="https://bball-scoresheet.ben-smith-development.com">ScoreSheet ></a>
          </div>
        </div>

      </div>
    </div>
    
    <div class="display-container">
      <div id="banner-image">
      </div>
      <div class="page-display">
        <router-view/>
        <Footer/>
      </div>
    </div>
  </div>
</template>

<script>
  import Footer from "@/components/page_elements/Footer";

  export default {
    name: 'App',
    components: {
        Footer, 
    },
    data() {
      return {
        showMobileNav: false,
      }
    },
    methods: {
      toggleNav() {
        this.showMobileNav = !this.showMobileNav;
      },
      closeNav() {
        this.showMobileNav = false;
      },
    },
    created() {
      window.addEventListener("resize", this.closeNav);
    },
    destroyed() {
      window.removeEventListener("resize", this.closeNav);
    },
  }
  
</script>

<style lang="scss">
$tablet-width: 950px;

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
:root {
--color1: #013547;
--color2:#242425;
--color3: rgb(255, 220, 20);
}

html {
  box-sizing: border-box;
  overflow: auto;
}

*, *:before, *:after {
  box-sizing: inherit;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background: linear-gradient(180deg, rgba(6,16,65,1) 800px, rgba(36,36,37,1) 100%);
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: 15px;
  position: relative;

  @media only screen and (max-width: $tablet-width) {
    background: linear-gradient(180deg, rgba(6,16,65,1) 500px, rgba(36,36,37,1) 100%);
  }

}

#nav {
  padding: 2px;
  background: var(--color3);
  text-transform: uppercase;

  display: flex;
  justify-content: center;

}

#nav-block {
  a {
    background: var(--color3);
    border: 3px solid black;
    border-radius: 6px;
    padding: 12px 25px;
    margin: 0px 10px;
    font-weight: bold;
    font-size: 20px;
    color: #000; 
    width: 200px;
    
    &:link, &:visited {
      text-decoration: none;
    }

    &.router-link-exact-active {
      color: #fff;
      background: #000;
      border-color: #fff;
    }
  }
}

#inner-nav {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  position: relative;

  p {
    margin-top: 0px;
  }

  a {
    text-align: center;

     &:hover {
      background-color: #000000;
      color: #fff;
    }
  }

  @media only screen and (max-width: $tablet-width) {
    & > a:not(.fixed-link) {
      display: none;

    }
  }

}

#mobile-nav-container {
  height: 0px;
  position: relative;
}

#mobile-nav {
  transition: height 0.3s;
  overflow: hidden;
  height: 0;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  a {
    margin-top: 4px;
    text-align: center;
  }

  &.open {
    height: 192px;
  }
  
}

#banner-image {
  height: 800px;
  background-image: url("assets/images/backgroundLarge2.png");
  background-size: 1536px;
  background-position-x: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: $tablet-width) {
    background-size: $tablet-width;
  }

}

#mobile-button {
  cursor: pointer;
  transition: transform 0.3s;
  border: 4px solid #000;
  padding: 2px;
  background: var(--color3);

  @media only screen and (min-width: ($tablet-width + 1)) {
    display: none;
  }

  &.open {
    transform: scaleY(-1)
  }
}

.page-display {
  margin-top: -480px;

  @media only screen and (max-width: $tablet-width) {
    margin-top: -600px;
  }
}

.main-section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 1220px;
  padding: 0px 15px;
}

.main-section-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  
  border: 4px solid #fff;
  background-image: url("assets/images/blackGrid.png");
  border-radius: 0px 0px 25px 25px;
  max-width: 1000px;

}

.main-link-container {
  position: relative;
}

.site-heading {
  background: var(--color3);
  margin: 15px 0px;
  width: 100%;
  text-align: center;
  border-radius: 6px;
}

.main-page-link {
    position: relative;

    margin: 20px 10px 0px 10px;
    max-width: 1176px;
    padding: 8px;
    background-color: rgb(121, 115, 115);
  }

h1, h2, h3, h4 {
  color: #020202;
  margin: 0px;
}

*, p, div, span {
  font-family: 'Kanit', sans-serif;
}

body {
  margin: 0px;
}

</style>
