<template>
    <div class="project-link">
        <img @click="$router.push(routerLink)" :src="imageSource">
        <p class="link-text">{{text}}</p>
    </div>
</template>

<script>
export default {
    name: 'ProjectLink',
    props: {
        text: {
            default: '',
            type: String,
        },
        imageSource: {
            required: true,
            type: String,
        },
        routerLink: {
            type: Object,
            default: () => null,
        },
    }
}
</script>

<style lang="scss" scoped>
    .project-link {
        position: relative;
        display: flex;

        .link-text {
            position: absolute;

            text-transform: uppercase;
            margin: 0px;
            bottom: 7px;
            right: 12px;
            font-weight: bold;
            font-size: 25px;
            color: #fff;
            text-shadow: 2px 2px #000
        }

        img {
            width: 100%;
            background-color: rgb(121, 115, 115);
            padding: 6px;
            cursor: pointer;
            
            &:hover {
                background-color: rgb(255, 239, 10);
        }
    }
        
    .link-text{
        line-height: 1;
        text-align: right;
    }
   }
</style>