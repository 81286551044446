<template>
    <div class="featured-project-container">
        <div class="link-container">
            <ProjectLink 
                :text="titleText"
                :imageSource="imageSource"
                :routerLink="routerLink"
            />
        </div>
        <div class="details-container">
            <div class="title">{{titleText}}</div>
            <div class="details-text">{{detailsText}}</div>
        </div>
    </div>
</template>

<script>
import ProjectLink from "@/components/ProjectLink";

export default {
    name: 'FeatureLink',
    components: {
        ProjectLink, 
    },
    props: {
        titleText: {
            default: '',
            type: String,
        },
        imageSource: {
            required: true,
            type: String,
        },
        routerLink: {
            type: Object,
            default: () => null,
        },
        detailsText: {
            default: '',
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
    $tablet-width: 950px;

    .featured-project-container {
        width: 98%;
        display: flex;
        flex-direction: row;
        border: 4px solid #000;
        border-radius: 0px 0px 25px 25px;
        padding: 10px;
        background-image: url("../../assets/images/blackGrid.png");
        margin-top: 15px;

        @media only screen and (max-width: $tablet-width) {
            flex-wrap: wrap;
            max-width: 432px;
        }
    }

    .link-container {
        max-width: 412px;
        min-width: 262px;
    }

    .details-container {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $tablet-width) {
            width: 100%;
        }
    }

    .title {
        padding-left: 10px;
        text-transform: uppercase;
        font-size: 50px;
        width: 100%;
        color: #fff;

        @media only screen and (max-width: $tablet-width) {
            display: none;
        }
    }

    .details-text {
        font-size: 18px;
        color: #fff;
        padding: 10px;
        background: #000;
    }
    
</style>