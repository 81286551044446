<template>
    <div id="outer-footer-container">
        <div id="footer-container">
            Contact: 
            <a href="mailto:info@ben-smith-development.com"> info@ben-smith-development.com</a>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Footer',

}
</script>

<style lang="scss" scoped>

    #outer-footer-container {
        display: flex;
        justify-content: center;
    }

    #footer-container {
        margin-top: 50px;
        min-height: 50px;
        width: 100%;
        max-width: 1200px;
        border-radius: 3px;
        border: 3px white solid;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
    }


</style>